import 'bootstrap';
import Splide from "@splidejs/splide";

new Splide(".splide__php", {
  perPage: 3,
  arrows: false,
  pagination: false,
  breakpoints: {
    640: {
      perPage: 1,
      pagination: true,
    },
  },
}).mount();

new Splide(".splide__hosting-package", {
  perPage: 4,
  arrows: false,
  pagination: false,
  breakpoints: {
    640: {
      perPage: 1,
      arrows: true,
    },
  },
}).mount();

const btnMoreModules = document.getElementById("btn--moremodules");
btnMoreModules.addEventListener("click", (e) => {
  e.preventDefault();

  let moduleCollapse = document.querySelector(".section__module--collapse");
  if (moduleCollapse.classList.contains("collapsed"))
    moduleCollapse.classList.remove("collapsed");
  else moduleCollapse.classList.add("collapsed");
});
